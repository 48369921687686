<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="tertiary"></ion-menu-button>
          <ion-button color="secondary" @click="backAgenda">retour</ion-button>
        </ion-buttons>
        <ion-title>{{ $route.params.id }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-card-header>
          <ion-buttons>
            <ion-button @click="backAgenda"> changez jour </ion-button>
            <ion-button></ion-button>
            <ion-button></ion-button>
          </ion-buttons>
        </ion-card-header>
        <ion-card-content>
          <div v-for="(document, index) in documents" :key="index">
            <ion-card v-if="document.jour == $route.params.id ">
              <ion-card-content v-if="document.class == 'displayFlex'">
                <ion-card-title
                  >{{ document.heure }} {{ document.nom }}
                  {{ document.prenom }}</ion-card-title
                >
                <ion-button>{{ document.telephone }}</ion-button>
                <ion-button>{{ document.email }}</ion-button>
                <ion-button @click="ondeleteRdv(document)">{{
                  document.id
                }}</ion-button>
              </ion-card-content>
            <ion-card-content v-else >
              Voulez vous annuler le rdv de {{document.nom}} {{document.prenom}} de {{document.heure}}??
              <ion-buttons>
                <ion-button @click="deleteRdv(document)"> Oui </ion-button>
                <ion-button @click="document.class='displayFlex'"> Non </ion-button>
              </ion-buttons>
            </ion-card-content>
            </ion-card>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent,ref } from "vue";
import { IonCard, IonCardContent, IonPage } from "@ionic/vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import useDocument from '@/composables/useDocument'
export default defineComponent({
  components: {
    IonCard,
    IonCardContent,

    IonPage,
  },
  setup() {
    const router = useRouter();
    const state = {};
    const backAgenda = () => {
      router.push({ name: "Folder", params: { id: "agenda" } });
    };
    const { documents } = getCollection("patients","total");
    const { documents:list } = getCollection("list","createdAt");
    const userId = ref('')
   
   
   
    const ondeleteRdv = (data) => {
      data.class =  'rere';
    };
   
   


    const deleteRdv = async(data) => {
      
      //console.log(list.value);
      if (list.value != null) {
        list.value.forEach((element) => {
          if (element.email == data.email) {
            userId.value = element.id;
          }
        });
      }
      const { deleteDoc } = useDocument("patients", data.id);
      await deleteDoc();
      const {updateDoc} = useDocument("list",userId.value)
      await updateDoc({reservation:0})
    }

    return {deleteRdv, backAgenda, state, documents, ondeleteRdv };
  },
});
</script>

<style>
</style>